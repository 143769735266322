<template>
    <section class="app-ecommerce-details">

        <div class="d-flex justify-content-center">
            <div class="card">
                <div class="card-header font-weight-bold h4">
                    Version del sistema
                </div>
                <div class="card-body">
                    <table class="w-100">
                        <tr>
                            <td class="font-weight-bold pr-1">Php:</td>
                            <td class="text-right text-primary">8.1</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold pr-1">Laravel:</td>
                            <td class="text-right text-primary">9.52</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold pr-1">Node:</td>
                            <td class="text-right text-primary">8.12</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold pr-1">Vue:</td>
                            <td class="text-right text-primary">2.6</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold pr-1">Bootstrap:</td>
                            <td class="text-right text-primary">5</td>
                        </tr>
                    </table>
                </div>
                <div class="card-footer text-center">
                    <b-button variant="primary" @click="update()">Actualizar</b-button>
                </div>

            </div>
        </div>

    </section>
</template>
  
<script>

import {
    BCard, BCardBody, BRow, BCol, BCardText, BButton, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import { createHelpers } from "vuex-map-fields"

import Swal from "sweetalert2"


export default {
    name: "ListView",
    directives: {
        Ripple,
    },
    components: {
        // BSV
        BCard,
        BCardBody,
        BRow,
        BCol,
        BCardText,
        BButton,
        BCarousel,
        BCarouselSlide,
        BCardActions,
        Swal
    },

    computed: {
    },
    data() {
        return {
            showLoading: false,
        }
    },
    created() {
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        update() {

            Swal.fire({
                title: "Actualizando sistema",
                text: "Espere, pronto estara listo",
                icon: "warning",
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                footer: 'Steknos',
            });

            setTimeout(() => {
                Swal.close();
            }, 3000);

        },
    }
}
</script>
  
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
  